@import '@spotahome/landings-common/src/scss/base';

.hero-tenant {
  &__header-wrapper {
    background: transparent;
    width: 100%;
    min-width: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    padding-top: 10px;

    @include from-laptop {
      background: none;
      border-bottom: 1px solid $light;
      padding: 0px 50px;
    }

    &--blue-background {
      background-color: $info;
    }
  }

  &__wrapper {
    color: $dark;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    gap: $spacing-xxl;
    background-image: url("https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-mobile.svg");
    background-repeat: no-repeat;
    background-position: 0 100%;

    @include from-laptop {
      height: auto;
      max-height: none;
      flex-direction: column;
      justify-content: space-around;
      gap: 2 * $spacing-xxl;
      background-image: url("https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-desktop.svg");
    }
  }

  &__container--blue-background {
    background-color: $info;
    background-image: url("https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-mobile-white.svg");
    gap: $spacing-xl * 3;
    background-position: 0 104%;
    background-size: 70%;

    @include from-tablet {
      background-size: 50%;
      gap: $spacing-xl * 4;
    }

    @include from-laptop {
      background-size: 35%;
      max-height: none;
      flex-direction: column;
      justify-content: space-around;
      gap: 2 * $spacing-xxl;
      background-image: url("https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/home-monuments-redesign-mobile-white.svg");
    }
  }

  &__trustpilot-wrapper {
    max-width: 50%;
    flex: 1 1;
    display: flex;
    justify-content: right;

    iframe {
      max-width: 80%;
    }
  }

  &__trustpilot-wrapper-redesign {
    flex: 1 1;
    display: flex;
    place-content: center;

    iframe {
      max-width: 80%;
    }
  }

  &__container-bottom {
    display: flex;
    justify-content: flex-end;
    @include max-container-homepage;
    @include from-laptop {
      padding: 0;
      margin-bottom: $spacing-xxl;
    }
  }

  &__monument {
    flex: 1 1;

    img {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    position: relative;
    color: inherit;
    padding: 0 $spacing-l;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;

    @include from-laptop {
      padding: $spacing-xl $spacing-m 0px $spacing-m;
    }

    @include from-desktop {
      max-width: 1144px;
    }

    &--blue {
      padding: 0 $spacing-m;

      @include from-tablet {
        padding: 0 $spacing-xxxxl;
      }
    }
  }

  &__info-title {
    @include font-title;
    @include landing-heading-redesign;
    text-align: left;
    padding-bottom: 0;

    @include from-tablet {
      text-align: center;
      padding-bottom: $spacing-l;
    }

    &--smaller {
      @include font-xxxl;
      margin-top: $spacing-xxxl;
      text-align: center;

      @include from-tablet {
        @include font-xxxxxxl;
      }
    }

  }

  &__info-content {
    width: 100%;

    @include from-laptop {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 760px;
    }
  }

  &__info-content-reviews {
    position: absolute;
    bottom: 0;

    @include from-laptop {
      display: none;
    }
  }

  &__info-subtitle {
    @include font-l;
    @include font-light;
    text-align: left;

    @include from-tablet {
      text-align: center;
    }

    b {
      @include font-bold;
    }

    &--lighter {
      color: $medium;
      @include font-m;
      text-align: center;
    }
  }

  &__info-rating {
    position: absolute;
    bottom: $spacing-l;

    @include from-laptop {
      position: inherit;
    }
  }

  &__city-selector {
    margin-top: 34px;
    > div {
      border-radius: $spacing-xl;
      @include from-laptop {
        border-radius: $spacing-m;
      }
      background-color: white;
    }

    .city-selector__cta {
      font-weight: bold;
    }

    @include from-laptop {
      width: 800px;
      border: 1px solid $light;
      background-color: white;
    }
    &--bigger {
      border-radius: 40px;
      border: none;
    }  }

  &__deposit-protection {
    border-radius: 100px !important;
    padding: $spacing-xs !important;
    text-transform: uppercase;
    display: inline-flex !important;
    align-items: center;
  }

  &__historical-metrics--grey {
    background-color: $ultra-light !important;
  }
}
